// Masks... still only for the Zero CTRL char.
// + Available / allowed chars.
// + Visual representation of mask / empty chars.
// - The "real" value. Keeping and providing access to it.
// - What about partial states, when the "real" value is not valid?
// - How to deal with selection?
// - Mobile...
// - Display on input: right to left, left to right, always show complete mask.
// Web Componets
// - Shadow domw: What are its (dis)advantages? When to (not) use it?
// Other
// - Use a monospace font to prevent mask from "jiggling"?
// - Put this on a file of its own? Parcel organization.
// - How much of the code should be elm or JS?
// - How to test this? Parcel organization + JS tests.

// Tests (extract a state object?):
// - home when the first char is not a "slot" (or end with last...)
// - sequence of "non-slot" chars
// - backspace, delete...
// - bounds
// - changing the mask

// TODO: Shift and selection in general.
// TODO: Optional 9 on mobile '(00) 90000-0000'... no idea how to do that.

// BUG: Alt+Tabing makes a select all !?!

function nextSlot (mask, position) {
  var newPosition = Math.min(mask.length, position + 1)
  while ((newPosition < mask.length) && (!MaskInput.ctrlChars.test(mask[newPosition]))) {
    newPosition = newPosition + 1
  }
  return Math.max(0, Math.min(mask.length, newPosition))
}

function nextSlotGroup (mask, position) {
  var newPosition = Math.min(mask.length, position + 1)
  while ((newPosition < mask.length) && (MaskInput.ctrlChars.test(mask[newPosition]))) {
    newPosition = newPosition + 1
  }
  return nextSlot(mask, newPosition)
}

function previousSlot (mask, position) {
  var newPosition = Math.max(0, position - 1)
  while ((newPosition > 0) && (!MaskInput.ctrlChars.test(mask[newPosition]))) {
    newPosition = newPosition - 1
  }
  return Math.max(0, Math.min(mask.length, newPosition))
}

function previousSlotGroup (mask, position) {
  if (position <= 0) {
    return 0
  }
  var newPosition = Math.max(0, position - 1)
  while ((newPosition > 0) && (MaskInput.ctrlChars.test(mask[newPosition]))) {
    newPosition = newPosition - 1
  }
  if (newPosition <= 0) {
    return 0
  } else {
    if (newPosition === (position - 1)) {
      return previousSlotGroup(mask, newPosition)
    } else {
      return nextSlot(mask, newPosition)
    }
  }
}

class MaskInput extends HTMLElement {
  static ctrlChars = /^[0]$/
  // WARNING: placeholder is an attribute AND a property... elm-ui uses it as a property (apparently)
  static observedAttributes = ['class', 'mask', 'placeholder', 'readonly', 'value', 'style']
  // #mask = '00----00.0 (123)'
  #emptySpace = '_'
  #emptyValue = ''
  #mask = ''
  #selectionStart = 0
  #selectionEnd = 0
  #selectionType = 'none' // 'forward', 'backward'
  #value = ''

  #moveCursorToValidPosition(toTheRight, position) {
    let mask = this.#mask
    // var newPosition = Math.max(0, Math.min(position, mask.length))
    var newPosition = position
    if (toTheRight) {
      while ((newPosition < mask.length) && (!MaskInput.ctrlChars.test(mask[newPosition]))) {
        newPosition = newPosition + 1
      }
    } else {
      while ((newPosition > 0) && (!MaskInput.ctrlChars.test(mask[newPosition]))) {
        newPosition = newPosition - 1
      }
    }
    return Math.max(0, Math.min(mask.length, newPosition))
  }

  #updateText(key, ctrl, shift, selectionStart, selectionEnd) {
    // console.log('updateText =>', key, ctrl, shift, selectionStart, selectionEnd, this.#selectionStart, this.#selectionEnd)
    let emptyValue = this.#emptyValue
    let mask = this.#mask
    let position = this.#selectionStart
    let rangeSelected = selectionStart !== selectionEnd

    let maskType = mask[position]
    var newSelectionStart = this.#selectionStart
    var newSelectionEnd = this.#selectionEnd
    var newSelectionType = this.#selectionType
    var newText = this.#value === '' ? emptyValue : this.#value

    switch (key) {
      case 'ArrowLeft':
        // console.log('left (before):', newSelectionType, newSelectionStart, newSelectionEnd)
        if ((newSelectionType === 'none') || (newSelectionType === 'backward')) {
          if (newSelectionStart > 0) {
            if (ctrl) {
              newSelectionStart = previousSlotGroup(this.#mask, newSelectionStart)
            } else {
              newSelectionStart = previousSlot(this.#mask, newSelectionStart)
            }
            if (shift) {
              newSelectionEnd = newSelectionEnd
            } else {
              newSelectionEnd = newSelectionStart
            }
            if (newSelectionStart !== newSelectionEnd) {
              newSelectionType = 'backward'
            }
          }
        } else {
          if (ctrl) {
            newSelectionEnd = previousSlotGroup(this.#mask, newSelectionEnd)
          } else {
            newSelectionEnd = previousSlot(this.#mask, newSelectionEnd)
          }
          if (shift) {
            newSelectionStart = newSelectionStart
          } else {
            newSelectionStart = newSelectionEnd
          }
        }
        // console.log('left (after):', newSelectionType, newSelectionStart, newSelectionEnd)
        break
      case 'ArrowRight':
        // console.log('right (before):', newSelectionType, newSelectionStart, newSelectionEnd)
        if (newSelectionStart < mask.length) {
          if ((newSelectionType === 'none') || (newSelectionType === 'forward')) {
            if (ctrl) {
              newSelectionEnd = nextSlotGroup(this.#mask, newSelectionEnd)
            } else {
              newSelectionEnd = nextSlot(this.#mask, newSelectionEnd)
            }
            if (shift) {
              newSelectionStart = newSelectionStart
            } else {
              newSelectionStart = newSelectionEnd
            }
            if (newSelectionStart !== newSelectionEnd) {
              newSelectionType = 'forward'
            }
          } else {
            if (ctrl) {
              newSelectionStart = nextSlotGroup(this.#mask, newSelectionStart)
            } else {
              newSelectionStart = nextSlot(this.#mask, newSelectionStart)
            }
            if (shift) {
              newSelectionEnd = newSelectionEnd
            } else {
              newSelectionEnd = newSelectionStart
            }
          }
        }
        // console.log('right (after):', newSelectionType, newSelectionStart, newSelectionEnd)
        break
      case 'Backspace':
        // TODO: CTRL + Backspace = erase all to the begining (cursor position set to zero)
        if (selectionStart !== selectionEnd) {
          // TODO: Check if it is possible that end < start.
          for (let i = selectionStart; i < selectionEnd; i++) {
            newText = newText.slice(0, i) + emptyValue[i] + newText.slice(i + 1)
          }
          newSelectionStart = Math.min(selectionStart, selectionEnd)
        } else {
          if (newSelectionStart > 0) {
            newSelectionStart = newSelectionStart - 1
            newText = newText.slice(0, newSelectionStart) + emptyValue[newSelectionStart] + newText.slice(newSelectionStart + 1)
            while ((newSelectionStart > 0) && (!MaskInput.ctrlChars.test(mask[newSelectionStart]))) {
              newSelectionStart = newSelectionStart - 1
              newText = newText.slice(0, newSelectionStart) + emptyValue[newSelectionStart] + newText.slice(newSelectionStart + 1)
            }
          }
        }
        newSelectionEnd = newSelectionStart
        break
      case 'Delete':
        if (selectionStart !== selectionEnd) {
          // TODO: Check if it is possible that end < start.
          for (let i = selectionStart; i < selectionEnd; i++) {
            newText = newText.slice(0, i) + emptyValue[i] + newText.slice(i + 1)
          }
          newSelectionStart = Math.min(selectionStart, selectionEnd)
        } else {
          if (newSelectionStart < mask.length) {
            newText = newText.slice(0, newSelectionStart) + emptyValue[newSelectionStart] + newText.slice(newSelectionStart + 1)
          }
        }
        newSelectionEnd = newSelectionStart
        break
      case 'End':
        if (shift) {
          if (newSelectionStart === newSelectionEnd) {
            newSelectionType = 'forward'
          }
          newSelectionEnd = this.#value.length
        } else {
          newSelectionStart = this.#value.length
          newSelectionEnd = this.#value.length
        }
        break
      case 'Home':
        if (shift) {
          if (newSelectionStart === newSelectionEnd) {
            newSelectionType = 'backward'
          }
          newSelectionStart = 0
        } else {
          newSelectionStart = 0
          newSelectionEnd = 0
        }
        break
      case 'Control':
        break
      case 'Shift':
        break
      default:
        if (newSelectionStart < mask.length) {
          switch (maskType) {
            case "0": // Accepts digits only [0-9].
              if (/^[0-9]$/.test(key)) {
                newText = newText.slice(0, newSelectionStart) + key + newText.slice(newSelectionStart + 1)
                newSelectionStart =  this.#moveCursorToValidPosition(true, newSelectionStart + 1)
              }
              break
            default:
              // TODO: Does not work for consecutive non-slot mask chars.
              newText = newText.slice(0, newSelectionStart) + emptyValue[newSelectionStart] + key + newText.slice(newSelectionStart + 1)
              newSelectionStart = newSelectionStart + 2
          }
        }
        newSelectionEnd = newSelectionStart
    }

    if (newSelectionStart === newSelectionEnd) {
      newSelectionType = 'none'
    }

    this.#selectionStart = newSelectionStart
    this.#selectionEnd = newSelectionEnd
    this.#selectionType = newSelectionType
    if (newText == this.#emptyValue) {
      this.#value = ''
    } else {
      this.#value = newText
    }
  }

  constructor() {
    super()
    // Structure.
    this.input = document.createElement('input')
    this.input.type = 'text'

    this.input.addEventListener('input', (e) => {
      // console.log(`Input => (start: ${e.target.selectionStart}, end: ${e.target.selectionEnd}, sstart: ${this.#selectionStart}, send: ${this.#selectionEnd})`)
      // console.log(`Input => (key: ${e.key}, start: ${e.target.selectionStart}, end: ${e.target.selectionEnd}, dir: ${e.target.selectionDirection})`)
      this.input.value = this.#value
      this.input.setSelectionRange(this.#selectionStart, this.#selectionEnd)
    })

    this.input.addEventListener('keydown', e => {
      let key = e.key
      let selectionStart = e.target.selectionStart
      let selectionEnd = e.target.selectionEnd
      // console.log('--------------------------------------------------')
      // console.log(`Keydown => (key: ${key}, start: ${selectionStart}, end: ${selectionEnd}, text: ${e.target.value})`)
      // console.log(`Keydown => (key: ${e.key}, start: ${e.target.selectionStart}, end: ${e.target.selectionEnd}, dir: ${e.target.selectionDirection})`)
      this.#updateText(key, e.ctrlKey, e.shiftKey, selectionStart, selectionEnd)
      // The following keys do not generate an input event, so we force one.
      if ((key === 'ArrowLeft') || (key === 'ArrowRight') || (key === 'Backspace')|| (key === 'Delete') || (key === 'Home') || (key === 'End')) {
        e.preventDefault()
        this.input.dispatchEvent(new Event('input', {bubbles: true}))
      }
    })

    this.input.addEventListener('click', e => {
      console.log(`Click => (start: ${e.target.selectionStart}, end: ${e.target.selectionEnd}, dir: ${e.target.selectionDirection})`)
      //
      this.#selectionStart = this.#moveCursorToValidPosition(true, e.target.selectionStart)
      this.#selectionEnd = this.#moveCursorToValidPosition(true, e.target.selectionEnd)
      this.#selectionType = (this.#selectionStart === this.#selectionEnd) ? 'none' : e.target.selectionDirection
      this.input.dispatchEvent(new Event('input', {bubbles: true}))
    })

    this.input.addEventListener('paste', e => {
      console.log('Pasted Data: ', e.clipboardData.getData('text'))
      console.log('Pasted Position: ', e.target.selectionStart)
    })
  }
  get mask() {
    return this.#mask
  }
  set mask(newValue) {
    this.#mask = newValue
    this.#emptyValue = this.#mask.replaceAll('0', this.#emptySpace)
  }
  get placeholder() {
    return this.input.placeholder
  }
  set placeholder(newValue) {
    this.input.placeholder = newValue
  }
  get readonly() {
    return this.input.readonly
  }
  get value() {
    return this.input.value
  }
  set value(newValue) {
    // TODO: Improve validity check.
    // Invalid values are ignored.
    if (newValue === '' || newValue.length === this.#mask.length) {
      this.#value = newValue
      this.input.value = this.#value
    }
  }
  attributeChangedCallback(name, oldValue, newValue) {
    // Keeps attributes in observedAttributes synchronized.
    this.input.setAttribute(name, newValue)
    // Attributes that are also writable properties.
    if (['mask', 'placeholder'].includes(name)) {
      this[name] = newValue
    }
    // Boolean attributes work by existing or not.
    if (['readonly'].includes(name) && (newValue === null)) {
      this.input.removeAttribute(name)
    }
  }
  connectedCallback() {
    // console.log('MaskInput: connected')
    if (this.isConnected) {
      console.log('connectedCallback / this.isConnected')
      this.appendChild(this.input)
    }
  }
  // disconnectedCallback() {
  //   console.log('MaskInput: disconnected')
  //   // this.removeChild(this.input)
  // }
}

customElements.define('mask-input', MaskInput)
