import { Elm } from './Main.elm'
import url_logo from './static/logo.png'
import url_logo_nome from './static/logo_nome.png'
import './lib/mask_input.js'

document.addEventListener('DOMContentLoaded', () => {
  const debugUser = user => {
    console.log('=== usuário =====================')
    console.log('displayName:', user.displayName)
    console.log('email:', user.email)
    console.log('emailVerified:', user.emailVerified)
    console.log('isAnonymous:', user.isAnonymous)
    console.log('metadata:', user.metadata)
    console.log('phoneNumber:', user.phoneNumber)
    console.log('photoURL:', user.photoURL)
    console.log('providerData:', user.providerData)
    console.log('providerId:', user.providerId)
    console.log('refreshToken:', user.refreshToken)
    console.log('tenantId:', user.tenantId)
    console.log('uid:', user.uid)
    console.log('=== usuário =====================')
    user.getIdTokenResult().then(tokenResult => {
      console.log('=== token =======================')
      console.log('authTime:', tokenResult.authTime)
      console.log('claims:', tokenResult.claims)
      console.log('expirationTime:', tokenResult.expirationTime)
      console.log('issuedAtTime:', tokenResult.issuedAtTime)
      console.log('signInProvider:', tokenResult.signInProvider)
      console.log('signInSecondFactor:', tokenResult.signInSecondFactor)
      console.log('token:', tokenResult.token)
      console.log('=== token =======================')
    })
  }
  const logToken = user => {
    user.getIdTokenResult().then(tokenResult => {
      console.log('=== token =======================')
      console.log('expirationTime:', new Date(tokenResult.expirationTime))
      console.log('issuedAtTime:', new Date(tokenResult.issuedAtTime))
      console.log(tokenResult.token)
      console.log('=== token =======================')
    })
  }

  // Masks... still only for the Zero CTRL char.
  // + Available / allowed chars.
  // + Visual representation of mask / empty chars.
  // - The "real" value. Keeping and providing access to it.
  // - What about partial states, when the "real" value is not valid?
  // - How to deal with selection?
  // - Mobile...
  // - Display on input: right to left, left to right, always show complete mask.
  // Web Componets
  // - Shadow domw: What are its (dis)advantages? When to (not) use it?
  // Other
  // - Use a monospace font to prevent mask from "jiggling"?
  // - Put this on a file of its own? Parcel organization.
  // - How much of the code should be elm or JS?
  // - How to test this? Parcel organization + JS tests.

  // Tests (extract a state object?):
  // - home when the first char is not a "slot" (or end with last...)
  // - sequence of "non-slot" chars
  // - backspace, delete...
  // - bounds
  // - changing the mask

  // TODO: Shift and selection in general.
  // TODO: Optional 9 on mobile '(00) 90000-0000'... no idea how to do that.

  // Firebase
  firebase.initializeApp({
    "apiKey": "AIzaSyAjJAznSzBXu2rKTsge-rPWjWkEhcgArtE",
    "authDomain": "auth.hoynk.space",
    "projectId": "tst01-c60c7",
  })
  // Firebase UI
  class FirebaseAuthUI extends HTMLElement {
    constructor() {
      super()
      this.container = document.createElement('div')
      // Initialize the FirebaseUI Widget using Firebase.
      var ui = firebaseui.auth.AuthUI.getInstance()
      if (ui) {
        ui.reset()
      } else {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      var uiConfig = {
        callbacks: {
          // Return false not to trigger a redirect.
          signInSuccessWithAuthResult: (authResult, redirectUrl) => { return false },
          // Unrecoverable error encontered during the sign-in process (firebaseui.auth.AuthUIError).
          signInFailure: (error) => { console.log(error.code) },
          // Triggered the first time the widget is rendered (ex. hide a loder).
          uiShown: () => { }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: '<url-to-redirect-to-on-success>',
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
      }
      ui.start(this.container, uiConfig)
    }
    // Invoked each time the custom element is appended into a document-connected element.
    // This will happen each time the node is moved, and may happen before the element's contents have been fully parsed.
    // Note: may be called once your element is no longer connected, use Node.isConnected to make sure.
    connectedCallback() { this.appendChild(this.container) }
    // FIX: Not sure that is the right thing to do.
    disconnectedCallback() { this.removeChild(this.container) }
    attributeChangedCallback() { }
  }
  customElements.define('firebase-auth-ui', FirebaseAuthUI)

  // Elm and its ports...
  var today = new Date()
  var app = Elm.Main.init({
    node: document.querySelector('main'),
    flags: {
      width: window.innerWidth,
      height: window.innerHeight,
      todayDay: today.getDate(),
      todayMonth: today.getMonth()+1,
      todayYear: today.getFullYear(),
      urlLogo: url_logo,
      urlLogoNome: url_logo_nome
    }
  })
  app.ports.signOut.subscribe(() => { firebase.auth().signOut() })

  const refreshToday = (() => {
    var timeoutId = null
    return () => {
      clearTimeout(timeoutId)
      // Reports current today to app.
      var today = new Date()
      app.ports.setToday.send({day: today.getDate(), month: today.getMonth()+1, year: today.getFullYear()})
      // Sets a timer to report when "today" has changed.
      var tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate()+1)
      tomorrow.setHours(0, 0, 0, 0)
      timeoutId = setTimeout(() => {
        refreshToday()
      }, tomorrow - today)
    }
  })()

  const refreshToken = (() => {
    const MAX_TIME_TO_REFRESH_MS = 5 * 60 * 1000
    var timeoutId = null
    return async () => {
      clearTimeout(timeoutId)
      if (firebase.auth().currentUser) {
        // Refreshes the token if needed.
        const tokenResult = await firebase.auth().currentUser.getIdTokenResult(false)
        const msToExpire = (new Date(tokenResult.expirationTime)) - (new Date())
        firebase.auth().currentUser.getIdTokenResult(msToExpire < MAX_TIME_TO_REFRESH_MS)
        // Sets a timer to refresh the token again.
        timeoutId = setTimeout(refreshToken, msToExpire - MAX_TIME_TO_REFRESH_MS)
      }
    }
  })()

  // Listen for changes to the signed-in user's ID token, which includes sign-in, sign-out, and token refresh events.
  firebase.auth().onIdTokenChanged(user => {
    if (user) {
      // logToken(user)
      user.getIdTokenResult().then(tokenResult => {
        app.ports.signedIn.send({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          token: tokenResult.token
        })
        refreshToken()
      })
    }
    else {
      app.ports.signedOut.send(null)
    }
  })

  refreshToday()

  document.onvisibilitychange = () => {
    if (document.visibilityState === "visible") {
      refreshToday()
      refreshToken()
    }
  };
})
